import { createBrowserRouter } from "react-router-dom";
import LoginPage from "../../Auth/pages/Login/Login";
import PrivateRoute from "../../Auth/components/PrivateRoute";
import Layout from "../components/Layout/Layout";
import Home from "../../Home/pages/Home/Home";
import { Spinner } from "@fluentui/react-components";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <PrivateRoute loader={<Spinner />}>
          <Home />
        </PrivateRoute>
      </Layout>
    ),
  },
  {
    path: "/login",
    element: (
      <Layout>
        <LoginPage />
      </Layout>
    ),
  },
]);

export default router;
