import { createAction } from "@reduxjs/toolkit";

export interface ThingConnected {
  connected: boolean;
}

export const thingConnectedAction = createAction(
  "thing/connected",
  (data: ThingConnected) => ({
    payload: data,
  })
);
