import { Container } from "./UserInfo.styles";
import { Button, Persona } from "@fluentui/react-components";
import UserStats from "./UserStats/UserStats";
import { UserInfoProps } from "./UserInfo.interface";

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  return (
    <Container>
      <Persona
        size='huge'
        primaryText={user.email}
        secondaryText={user.role}
        tertiaryText={user.workspace.name}
        textPosition='below'
        presence={{ status: "available" }}
      />
      <Button>Zamenjaj pilota</Button>
      <UserStats />
    </Container>
  );
};

export default UserInfo;
