import React from "react";
import { Container } from "./Logo.styles";
import { Text } from "@fluentui/react-components";

const Logo = () => {
  return (
    <Container>
      <img
        width={64}
        height={64}
        src='/images/app_logo.svg'
        alt='App Name Document'
      />
      <Text font='base' size={500} weight='semibold'>
        {process.env.REACT_APP_NAME}
      </Text>
    </Container>
  );
};

export default Logo;
