export enum AuthValueKey {
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
}
export type JwtInfo = {
  aud?: string;
  exp: number;
  iat: number;
  iss: string;
  id: string;
  workspaceId: string;
};
