import ErrorPage from "./common/Error";
import { ErrorPageSeverity } from "./common/Error.interface";

const ErrorNotVerified: React.FC<{ info?: string }> = ({ info }) => {
  return (
    <ErrorPage
      severity={ErrorPageSeverity.CRITICAL}
      title='Application not Verified'
      description='Please verify the application to continue.'
      additionalInfo={info}
    />
  );
};

export default ErrorNotVerified;
