/* istanbul ignore file */

//LETS FIND A WAY HOW TO TEST THIS SEPARATELY!

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import authApi from "../apis/auth.apis";

import { LocalStorageService } from "../../Core/helpers/core.helpers";
import { AuthValueKey } from "../auth.interface";
import pilotBridge from "../../Core/services/pilot-bridge";
import { ComponentName } from "../../Core/services/pilot-bridge/interface";

export type TokenState = {
  accessToken: string | null;
  refreshToken: string | null;
};

const slice = createSlice({
  name: "token",
  initialState: {
    accessToken: LocalStorageService.get(AuthValueKey.ACCESS_TOKEN) || null,
    refreshToken: LocalStorageService.get(AuthValueKey.REFRESH_TOKEN) || null,
  },
  reducers: {
    setAccessToken: (state, { payload }: PayloadAction<string>) => {
      LocalStorageService.set(AuthValueKey.ACCESS_TOKEN, payload);
      state.accessToken = payload;
    },
    removeAccessToken: (state) => {
      LocalStorageService.remove(AuthValueKey.ACCESS_TOKEN);
      state.accessToken = null;
    },
    setRefreshToken: (state, { payload }: PayloadAction<string>) => {
      LocalStorageService.set(AuthValueKey.REFRESH_TOKEN, payload);
      state.refreshToken = payload;
    },
    removeRefreshToken: (state) => {
      LocalStorageService.remove(AuthValueKey.REFRESH_TOKEN);
      state.refreshToken = null;
    },
    updateTokensFromStorage: (state) => {
      state.accessToken =
        LocalStorageService.get(AuthValueKey.ACCESS_TOKEN) || null;
      state.refreshToken =
        LocalStorageService.get(AuthValueKey.REFRESH_TOKEN) || null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        LocalStorageService.set(AuthValueKey.ACCESS_TOKEN, payload.token);
        LocalStorageService.set(
          AuthValueKey.REFRESH_TOKEN,
          payload.refreshToken
        );
        state.accessToken = payload.token;
        state.refreshToken = payload.refreshToken;

        pilotBridge.setToken(payload.token);
        pilotBridge.setComponentParam(ComponentName.Api, {
          host: `${process.env.REACT_APP_API_URL}/`,
          token: payload.token,
        });
        pilotBridge.loadComponent(
          ComponentName.Api,
          pilotBridge.getComponentParam(ComponentName.Api)
        );
      }
    );
    builder.addMatcher(
      authApi.endpoints.refreshToken.matchFulfilled,
      (state, { payload }) => {
        LocalStorageService.set(AuthValueKey.ACCESS_TOKEN, payload.data.token);
        LocalStorageService.set(
          AuthValueKey.REFRESH_TOKEN,
          payload.data.refreshToken
        );
        state.accessToken = payload.data.token;
        state.refreshToken = payload.data.refreshToken;

        pilotBridge.setToken(payload.data.token);
        pilotBridge.setComponentParam(ComponentName.Api, {
          host: `${process.env.REACT_APP_API_URL}/`,
          token: payload.data.token,
        });
        pilotBridge.loadComponent(
          ComponentName.Api,
          pilotBridge.getComponentParam(ComponentName.Api)
        );
      }
    );
  },
});

export const {
  setAccessToken,
  removeAccessToken,
  setRefreshToken,
  removeRefreshToken,
  updateTokensFromStorage,
} = slice.actions;

export default slice.reducer;

export const selectAccessToken = (state: {
  token: TokenState;
}): string | null => state.token.accessToken;

export const selectRefreshToken = (state: {
  token: TokenState;
}): string | null => state.token.refreshToken;
