import { Text } from "@fluentui/react-components";
import React from "react";
import { Container, Statuses } from "./DeviceInfo.styles";
import IconTextStatus from "../IconTextStatus/IconTextStatus";
import { AirplaneRegular, XboxControllerRegular } from "@fluentui/react-icons";
import pilotBridge from "../../../../Core/services/pilot-bridge";

const DeviceInfo: React.FC = () => {
  return (
    <Container>
      <Text font='base' size={300} weight='medium'>
        Naprava
      </Text>
      <Statuses>
        <IconTextStatus
          Icon={AirplaneRegular}
          text={pilotBridge.getAircraftSN()}
        />
        <IconTextStatus
          Icon={XboxControllerRegular}
          text={pilotBridge.getRemoteControllerSN()}
        />
      </Statuses>
    </Container>
  );
};

export default DeviceInfo;
