import React, { useEffect } from "react";
import { Container, StyledCard } from "./HubInfo.styles";
import {
  Button,
  CardHeader,
  CardPreview,
  Divider,
  Text,
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import DeviceInfo from "./DeviceInfo/DeviceInfo";
import ConnectionInfo from "./ConnectionInfo/ConnectionInfo";
import {
  ThingConnected,
  thingConnectedAction,
} from "../../../Core/actions/thing";
import {
  addListener,
  createAction,
  isAnyOf,
  PayloadAction,
  PrepareAction,
  removeListener,
} from "@reduxjs/toolkit";
import { store } from "../../../Core/stores/core.store";
import IconStatus from "./IconStatus/IconStatus";
import { IconStatusStatus } from "./IconStatus/IconStatus.interface";

const HubInfo: React.FC = () => {
  const [connected, setConnected] = React.useState(false);

  useEffect(() => {
    const listenerParams = {
      matcher: isAnyOf(
        thingConnectedAction as ReturnType<
          typeof createAction<PrepareAction<ThingConnected>>
        >
      ),
      effect: ({ payload }: PayloadAction<ThingConnected>) => {
        setConnected(payload.connected);
      },
    };

    store.dispatch(addListener(listenerParams));

    return () => {
      store.dispatch(removeListener(listenerParams));
    };
  }, []);

  return (
    <Container>
      <StyledCard orientation='horizontal' appearance='subtle' size='large'>
        <CardPreview>
          <img src='/images/app_logo.svg' alt='App Name Document' />
        </CardPreview>

        <CardHeader
          header={<Text weight='semibold'>{process.env.REACT_APP_NAME}</Text>}
          description={
            <IconStatus
              status={
                connected
                  ? IconStatusStatus.CONNECTED
                  : IconStatusStatus.DISCONNECTED
              }
            />
          }
          action={
            <Button
              appearance='transparent'
              icon={<MoreHorizontal20Regular />}
              aria-label='More options'
            />
          }
        />
      </StyledCard>
      <Divider appearance='strong' />
      <DeviceInfo />
      <Divider appearance='strong' />
      <ConnectionInfo />
    </Container>
  );
};

export default HubInfo;
