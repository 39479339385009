import { useForm } from "react-hook-form";
import { useLoginMutation } from "../../../apis/auth.apis";
import { getValidationSchema } from "./Form.resolver";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Input,
  Label,
  Toast,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { ToasterId } from "../../../../Core/core.enum";
import { ApiErrorResponse } from "../../../../Core/core.interface";
import { LockClosedRegular, PersonRegular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { LoginRequest } from "../../../apis/auth.apis.interface";
import { Form, FormField } from "./Form.styles";

const LoginForm = () => {
  const navigate = useNavigate();
  const [trigger] = useLoginMutation();

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<LoginRequest>({ resolver: yupResolver(getValidationSchema()) });
  const { dispatchToast } = useToastController(ToasterId.ROOT);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await trigger(data).unwrap();
      navigate("/");
    } catch (error) {
      const errorMessage = (error as ApiErrorResponse).data.message;

      dispatchToast(
        <Toast>
          <ToastTitle>{errorMessage}</ToastTitle>
        </Toast>,
        { intent: "error" }
      );
    }
  });

  return (
    <Form onSubmit={onSubmit}>
      <FormField>
        <Label htmlFor='email'>E-pošta</Label>
        <Input
          contentBefore={<PersonRegular />}
          id='email'
          {...register("email")}
        />
      </FormField>
      <FormField>
        <Label htmlFor='password'>Geslo</Label>
        <Input
          contentBefore={<LockClosedRegular />}
          id='password'
          type='password'
          {...register("password")}
        />
      </FormField>

      <Button type='submit'>Prijava</Button>
    </Form>
  );
};

export default LoginForm;
