import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { getAuthBaseQuery } from "../../Auth/helpers/auth.helpers";

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, api, extraOptions) => {
  return getAuthBaseQuery()(args, api, extraOptions);
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User", "Device"],
  endpoints: () => ({}),
});
