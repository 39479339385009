export enum ErrorPageSeverity {
  CRITICAL = "critical",
  WARNING = "warning",
  INFO = "info",
}

export interface ErrorPageProps {
  severity: ErrorPageSeverity;
  title: string;
  description?: string;
  additionalInfo?: string;
}
