import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import authApi from "../../Auth/apis/auth.apis";
import { AuthValueKey } from "../../Auth/auth.interface";
import { LocalStorageService } from "../helpers/core.helpers";
import { getDataFromAccessToken } from "../../Auth/helpers/auth.helpers";
import { LoginResponseWithToken } from "../../Auth/apis/auth.apis.interface";
import { CoreState } from "../core.interface";

const accessToken: string | null = LocalStorageService.get(
  AuthValueKey.ACCESS_TOKEN
);

// const setUserSentry = (userId: number) => {
//   SentryService.setSentryUser(`${userId}`);
// };

const getInitialState = () => {
  const userId = accessToken
    ? getDataFromAccessToken(accessToken).userId
    : null;

  const workspaceId = accessToken
    ? getDataFromAccessToken(accessToken).workspaceId
    : null;

  //   userId && setUserSentry(userId);

  return {
    userId,
    workspaceId,
  };
};

const setState = (state: CoreState, payload: LoginResponseWithToken) => {
  const { userId, workspaceId } = getDataFromAccessToken(payload.token);
  state.userId = userId;
  state.workspaceId = workspaceId;

  //   userId && setUserSentry(userId);
};

const slice = createSlice({
  name: "core",
  initialState: getInitialState(),
  reducers: {
    setUserId: (state, { payload }: PayloadAction<string | null>) => {
      state.userId = payload;
    },
    setWorkspaceId: (state, { payload }: PayloadAction<string | null>) => {
      state.workspaceId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        setState(state, payload);
      }
    );
  },
});

export default slice.reducer;

export const { setUserId, setWorkspaceId } = slice.actions;

export const selectWorkspaceId = (state: { core: CoreState }): string | null =>
  state.core.workspaceId;

export const selectUserId = (state: { core: CoreState }): string | null =>
  state.core.userId;
