import { thingConnectedAction } from "../../Core/actions/thing";
import pilotBridge from "../../Core/services/pilot-bridge";
import { selectWorkspaceId } from "../../Core/slices/core.slicers";
import { store } from "../../Core/stores/core.store";
import deviceApi from "../../Device/apis/device.apis";

export const connectCallback = async (arg: boolean) => {
  store.dispatch(thingConnectedAction({ connected: arg }));

  if (arg) {
    // Thing connected
    store.dispatch(
      deviceApi.endpoints.bindDevice.initiate({
        serialNumber: pilotBridge.getRemoteControllerSN(),
        workspaceId: selectWorkspaceId(store.getState()) as string,
      })
    );
  } else {
    // Thing disconnected
    store.dispatch(
      deviceApi.endpoints.unbindDevice.initiate(
        pilotBridge.getRemoteControllerSN()
      )
    );
  }
};
