import { Middleware, isRejectedWithValue } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../core.interface";

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = action.payload as ApiErrorResponse;

    console.error(error);
  }

  return next(action);
};

export const LocalStorageService = {
  get: (key: string): string | null => window.localStorage.getItem(key),
  set: (key: string, value: any): void =>
    window.localStorage.setItem(key, value),
  clear: (): void => window.localStorage.clear(),
  remove: (key: string): void => window.localStorage.removeItem(key),
};
