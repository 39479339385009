import React from "react";
import { StatsIconProps } from "./StatsIcon.interface";
import { Container, IconContainer } from "./StatsIcon.styles";
import { Text } from "@fluentui/react-components";

const StatsIcon: React.FC<StatsIconProps> = ({ Icon, text, value }) => {
  return (
    <Container>
      <IconContainer>
        <Icon fontSize='28' />
        <Text font='base' size={100} align='center'>
          {text}
        </Text>
      </IconContainer>
      <Text font='base' weight='bold' size={400} align='center'>
        {value}
      </Text>
    </Container>
  );
};

export default StatsIcon;
