import React, { useCallback, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import { isTokenExpired, isTokenValid } from "../helpers/utils/utils.helpers";
import { selectAccessToken, selectRefreshToken } from "../slices/token.slices";
import { useNavigate } from "react-router-dom";
import { useRefreshTokenMutation } from "../apis/auth.apis";

interface PrivateRouteProps {
  children: JSX.Element;
  loader: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, loader }) => {
  const accessToken = useSelector(selectAccessToken);
  const refreshToken = useSelector(selectRefreshToken);

  const [triggerTokenRefresh, { isLoading, isError }] =
    useRefreshTokenMutation();
  const navigate = useNavigate();

  const redirectToLoginConditions = useMemo(
    () => !refreshToken || isError,
    [isError, refreshToken]
  );

  const updateAccessToken = useCallback(async () => {
    if (refreshToken) {
      await triggerTokenRefresh({ refreshToken });
    }
  }, [refreshToken, triggerTokenRefresh]);

  useEffect(() => {
    if (redirectToLoginConditions) {
      navigate("/login");
    } else if (!accessToken) {
      updateAccessToken();
    } else if (
      accessToken &&
      (!isTokenValid(accessToken) || isTokenExpired(accessToken))
    ) {
      updateAccessToken();
    }
  }, [accessToken, updateAccessToken, redirectToLoginConditions, navigate]);

  if (isLoading || redirectToLoginConditions) {
    return loader;
  }

  return children;
};

export default PrivateRoute;
