import {
  AirplaneTakeOffFilled,
  ClockFilled,
  LocationRippleFilled,
} from "@fluentui/react-icons";
import StatsIcon from "./StatsIcon/StatsIcon";
import { Container } from "./UserStats.styles";

const UserStats = () => {
  return (
    <Container>
      <StatsIcon Icon={ClockFilled} text='Čas letenja' value={260} />
      <StatsIcon
        Icon={LocationRippleFilled}
        text='Preletena razdalja'
        value={2000}
      />
      <StatsIcon Icon={AirplaneTakeOffFilled} text='Število letov' value={14} />
    </Container>
  );
};

export default UserStats;
