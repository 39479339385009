import React from "react";
import { Container, IconText } from "./IconTextStatus.styles";
import { IconTextStatusProps } from "./IconTextStatus.interface";
import { Badge } from "@fluentui/react-components";

const IconTextStatus: React.FC<IconTextStatusProps> = ({
  Icon,
  text,
  status,
  color,
}) => {
  return (
    <Container>
      <IconText>
        <Icon fontSize='28' />
        <div>{text}</div>
      </IconText>
      {status && (
        <Badge appearance='tint' color={color}>
          {status}
        </Badge>
      )}
    </Container>
  );
};

export default IconTextStatus;
