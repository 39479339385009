export enum ComponentName {
  Thing = "thing",
  Liveshare = "liveshare",
  Api = "api",
  Ws = "ws",
  Map = "map",
  Tsa = "tsa",
  Media = "media",
  Mission = "mission",
}

export interface ThingParam {
  host: string;
  username: string;
  password: string;
  connectCallback: string;
}

export interface ApiParam {
  host: string;
  token: string;
}

export interface JsResponse {
  code: number;
  message: string;
  data: any;
}
