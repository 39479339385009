import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  border: "1px solid lightgray",
  borderRadius: "6px",
  gap: "1rem",
  justifyContent: "center",
  flex: 2,
});
