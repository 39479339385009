import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
});

export const Statuses = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
});
