import React from "react";
import { Container } from "./Layout.styles";
import { verifyLicense } from "./Layout.helpers";
import ErrorNotVerified from "../../pages/Error/NotVerified";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const verified = verifyLicense();

  return verified.result ? (
    <Container>{children}</Container>
  ) : (
    <ErrorNotVerified
      info={`appId: ${verified.appId}, appKey: ${verified.appKey}, appLicense: ${verified.appLicense}`}
    />
  );
};

export default Layout;
