import { ApiParam, ComponentName, JsResponse, ThingParam } from "./interface";

// if (process.env.NODE_ENV !== "production") {
//   Object.defineProperty(window, "djiBridge", {
//     value: {
//       platformLoadComponent: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       platformUnloadComponent: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       platformIsComponentLoaded: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: true,
//         });
//       },
//       platformSetWorkspaceId: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       platformSetInformation: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: true,
//         });
//       },
//       platformGetRemoteControllerSN: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "SNI94JO99SJF",
//         });
//       },
//       platformGetAircraftSN: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "SN39F4832SEJD125",
//         });
//       },
//       platformStopSelf: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       platformSetLogEncryptKey: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       platformClearLogEncryptKey: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       platformGetLogPath: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       platformVerifyLicense: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: true,
//         });
//       },
//       platformIsVerified: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: true,
//         });
//       },
//       thingGetConnectState: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: true,
//         });
//       },
//       thingGetConfigs: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: JSON.stringify({
//             host: "",
//             connectCallback: "",
//             username: "",
//             password: "",
//           }),
//         });
//       },
//       apiGetToken: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       apiSetToken: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       apiGetHost: () => {
//         return JSON.stringify({
//           code: 0,
//           message: "success",
//           data: "success",
//         });
//       },
//       onStopPlatform: () => {
//         return;
//       },
//     },
//   });
// }

export const components = new Map();
declare let window: any;

const pilotBridge = {
  init(): Map<ComponentName, any> {
    const thingParam: ThingParam = {
      host: "",
      connectCallback: "",
      username: "",
      password: "",
    };
    components.set(ComponentName.Thing, thingParam);
    const apiParam: ApiParam = {
      host: "",
      token: "",
    };
    components.set(ComponentName.Api, apiParam);

    return components;
  },

  getComponentParam(key: ComponentName): any {
    return components.get(key);
  },
  setComponentParam(key: ComponentName, value: any) {
    components.set(key, value);
  },
  loadComponent(name: string, param: any): string {
    console.log("loadComponent", name, param);
    return returnString(
      window.djiBridge.platformLoadComponent(name, JSON.stringify(param))
    );
  },
  unloadComponent(name: string): string {
    return returnString(window.djiBridge.platformUnloadComponent(name));
  },
  isComponentLoaded(module: string): boolean {
    return returnBool(window.djiBridge.platformIsComponentLoaded(module));
  },
  setWorkspaceId(uuid?: string): string {
    if (uuid) {
      return returnString(window.djiBridge.platformSetWorkspaceId(uuid));
    } else {
      return "";
    }
  },
  setPlatformMessage(
    platformName: string,
    title?: string,
    desc?: string
  ): boolean {
    return returnBool(
      window.djiBridge.platformSetInformation(
        platformName,
        title || "",
        desc || ""
      )
    );
  },
  getRemoteControllerSN(): string {
    return returnString(window.djiBridge.platformGetRemoteControllerSN());
  },
  getAircraftSN(): string {
    return returnString(window.djiBridge.platformGetAircraftSN());
  },
  stopwebview(): string {
    return returnString(window.djiBridge.platformStopSelf());
  },
  setLogEncryptKey(key: string): string {
    return window.djiBridge.platformSetLogEncryptKey(key);
  },
  clearLogEncryptKey(): string {
    return window.djiBridge.platformClearLogEncryptKey();
  },
  getLogPath(): string {
    return returnString(window.djiBridge.platformGetLogPath());
  },
  platformVerifyLicense(
    appId: string,
    appKey: string,
    appLicense: string
  ): { result: boolean; appId: string; appKey: string; appLicense: string } {
    return {
      result: returnBool(
        window.djiBridge.platformVerifyLicense(appId, appKey, appLicense)
      ),
      appId,
      appKey,
      appLicense,
    };
  },
  isPlatformVerifySuccess(): boolean {
    return returnBool(window.djiBridge.platformIsVerified());
  },
  isAppInstalled(pkgName: string): boolean {
    return returnBool(window.djiBridge.platformIsAppInstalled(pkgName));
  },
  getVersion(): string {
    return window.djiBridge.platformGetVersion();
  },

  // thing
  thingGetConnectState(): boolean {
    return returnBool(window.djiBridge.thingGetConnectState());
  },

  thingGetConfigs(): ThingParam {
    const thingParam = JSON.parse(window.djiBridge.thingGetConfigs());
    return thingParam.code === 0 ? JSON.parse(thingParam.data) : {};
  },

  // api
  getToken(): string {
    return returnString(window.djiBridge.apiGetToken());
  },
  setToken(token: string): string {
    return returnString(window.djiBridge.apiSetToken(token));
  },
  getHost(): string {
    return returnString(window.djiBridge.apiGetHost());
  },
  onBackClickReg() {
    window.djiBridge.onBackClick = () => {
      return false;
      //   if (
      //     root.$router.currentRoute.value.path ===
      //     "/" + ERouterName.PILOT_HOME
      //   ) {
      //     return false;
      //   } else {
      //     history.go(-1);
      //     return true;
      //   }
    };
  },
  onStopPlatform() {
    window.djiBridge.onStopPlatform = () => {
      localStorage.clear();
    };
  },
};

const returnBool = (response: string): boolean => {
  const res: JsResponse = JSON.parse(response);
  const isError = errorHint(res);
  if (JSON.stringify(res.data) !== "{}") {
    return isError && res.data;
  }
  return isError;
};

const returnString = (response: string): string => {
  const res: JsResponse = JSON.parse(response);
  return errorHint(res) ? res.data : "";
};

const returnNumber = (response: string): number => {
  const res: JsResponse = JSON.parse(response);
  return errorHint(res) ? res.data : -1;
};

const errorHint = (response: JsResponse): boolean => {
  if (response.code !== 0) {
    console.error(response.message);
    return false;
  }
  return true;
};

export default pilotBridge;
