import { Divider } from "@fluentui/react-components";
import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  marginTop: "5rem",
  gap: "2rem",
});

export const StyledDivider = styled(Divider)({
  height: "200px",
});
