import { tokens } from "@fluentui/react-components";
import styled from "styled-components";

interface ContainerProps {
  $isConnected: boolean;
}

export const Container = styled("div")<ContainerProps>(({ $isConnected }) => ({
  display: "flex",
  gap: "0.25rem",
  color: $isConnected
    ? tokens.colorPaletteLightGreenForeground3
    : tokens.colorPaletteRedForeground1,
}));
