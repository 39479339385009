import pilotBridge from "../../services/pilot-bridge";

export const verifyLicense = (): {
  result: boolean;
  appId: string;
  appKey: string;
  appLicense: string;
} => {
  const verify = pilotBridge.platformVerifyLicense(
    process.env.REACT_APP_DJI_APP_ID,
    process.env.REACT_APP_DJI_APP_KEY,
    process.env.REACT_APP_DJI_APP_LICENSE
  );
  return {
    result: verify.result && pilotBridge.isPlatformVerifySuccess(),
    appId: verify.appId,
    appKey: verify.appKey,
    appLicense: verify.appLicense,
  };
};
