import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  marginTop: "5rem",
});

export const Icon = styled("div")(({ color }) => ({
  fontSize: "100px",
  color: color,
}));

export const TitleDescription = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
});
