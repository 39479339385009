import { jwtDecode } from "jwt-decode";
import { JwtInfo } from "../../auth.interface";

export const decodeJwt = <T>(token: string) => {
  try {
    return jwtDecode<T>(token);
  } catch (error) {
    return;
  }
};

export const isTokenExpired = (token: string): boolean => {
  const decodedToken = decodeJwt<JwtInfo>(token);

  if (decodedToken) {
    const tokenExpInMiliSeconds = decodedToken.exp * 1000;
    return tokenExpInMiliSeconds < Date.now();
  } else {
    return true;
  }
};

export const isTokenValid = (token: string): boolean => !!decodeJwt(token);

export const hasValidTokens = (
  accessToken: string | null,
  refreshToken: string | null
): boolean =>
  !!accessToken &&
  !!refreshToken &&
  isTokenValid(accessToken) &&
  !isTokenExpired(accessToken);
