import styled from "styled-components";

export const Container = styled("div")({
  display: "flex",
  gap: "0.25rem",
  alignItems: "center",
});

export const IconText = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  width: "100%",
});
