import { Card, tokens } from "@fluentui/react-components";
import styled from "styled-components";

export const StyledCard = styled(Card)({
  width: "100%",
});

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  backgroundColor: tokens.colorNeutralBackground3,
  padding: "1rem",
  gap: "0.5rem",
  borderRadius: "6px",
  flex: 1,
  height: "100%",
});
