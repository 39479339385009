import pilotBridge from "../../../Core/services/pilot-bridge";
import LoginForm from "./Form/Form";
import { Container, StyledDivider } from "./Login.styles";
import Logo from "./Logo/Logo";

const LoginPage = () => {
  pilotBridge.setPlatformMessage(process.env.REACT_APP_NAME);

  return (
    <Container>
      <Logo />
      <StyledDivider vertical appearance='subtle' />
      <LoginForm />
    </Container>
  );
};

export default LoginPage;
