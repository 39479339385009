import React, { useEffect } from "react";
import { Container } from "./Home.styles";
import UserInfo from "../../components/UserInfo/UserInfo";
import HubInfo from "../../components/HubInfo/HubInfo";
import { useGetUserQuery } from "../../../User/apis/user.apis";
import pilotBridge from "../../../Core/services/pilot-bridge";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../Core/slices/core.slicers";
import {
  ComponentName,
  ThingParam,
} from "../../../Core/services/pilot-bridge/interface";
import { connectCallback } from "../../helpers/home.helpers";
const components = pilotBridge.init();

window.connectCallback = (arg: any) => {
  console.log("connectCallback", arg);
  connectCallback(arg);
};

const Home: React.FC = () => {
  const userId = useSelector(selectUserId);
  const { data: userData, isSuccess: isUserDataSuccess } = useGetUserQuery(
    userId as string,
    {
      skip: !userId,
    }
  );

  useEffect(() => {
    if (isUserDataSuccess) {
      pilotBridge.setPlatformMessage(
        process.env.REACT_APP_NAME,
        userData?.data?.workspace.name,
        userData?.data?.email
      );
      pilotBridge.setWorkspaceId(userData?.data?.workspaceId);

      const param: ThingParam = {
        host: process.env.REACT_APP_MQTT_HOST,
        username: userData?.data?.email,
        password: userData?.data?.mqttPassword,
        connectCallback: "connectCallback",
      };
      components.set(ComponentName.Thing, param);
      pilotBridge.loadComponent(
        ComponentName.Thing,
        components.get(ComponentName.Thing)
      );
    }
  }, [userData, isUserDataSuccess]);

  useEffect(() => {
    pilotBridge.onBackClickReg();
    pilotBridge.onStopPlatform();
  }, []);

  return (
    <Container>
      <HubInfo />
      {userData?.data && <UserInfo user={userData?.data} />}
    </Container>
  );
};

export default Home;
