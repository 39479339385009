import React, { PropsWithChildren } from "react";
import { Container, Icon, TitleDescription } from "./Error.styles";
import { ErrorPageProps, ErrorPageSeverity } from "./Error.interface";
import {
  ErrorCircleFilled,
  InfoFilled,
  WarningFilled,
} from "@fluentui/react-icons";
import { Text, tokens } from "@fluentui/react-components";

interface GetIcon {
  icon: React.ReactNode;
  color: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  severity,
  title,
  description,
  additionalInfo,
}) => {
  const getIcon = (): GetIcon => {
    switch (severity) {
      case ErrorPageSeverity.CRITICAL:
        return {
          icon: <ErrorCircleFilled />,
          color: tokens.colorPaletteRedForeground3,
        };
      case ErrorPageSeverity.INFO:
        return {
          icon: <InfoFilled />,
          color: tokens.colorBrandForeground1,
        };
      case ErrorPageSeverity.WARNING:
        return {
          icon: <WarningFilled />,
          color: tokens.colorPaletteYellowForeground3,
        };
    }
  };
  return (
    <Container>
      <Icon color={getIcon().color}>{getIcon().icon}</Icon>
      <TitleDescription>
        <Text size={700} weight='semibold'>
          {title}
        </Text>
        {description && <Text size={400}>{description}</Text>}
        {additionalInfo && <Text size={200}>{additionalInfo}</Text>}
      </TitleDescription>
    </Container>
  );
};

export default ErrorPage;
