import { Mutex } from "async-mutex";
import { LocalStorageService } from "../../Core/helpers/core.helpers";
import { AuthValueKey, JwtInfo } from "../auth.interface";
import tokenSlices from "../slices/token.slices";
import { decodeJwt } from "./utils/utils.helpers";
import { API_AUTH_EXCEPTIONS } from "../../Core/core.constants";
import { AUTH_API_URLS } from "../apis/auth.urls";
import { ApiResponse } from "../../Core/core.interface";
import { TokenProps } from "../apis/auth.apis.interface";
import {
  retry,
  fetchBaseQuery,
  FetchBaseQueryMeta,
  FetchBaseQueryError,
  FetchArgs,
  BaseQueryFn,
} from "@reduxjs/toolkit/query";

const mutex = new Mutex();

export const getAuthBaseQuery = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (currentHeaders, { endpoint, getState }) =>
      prepareAuthHeaders(
        currentHeaders,
        getState() as any & ReturnType<typeof tokenSlices>,
        endpoint,
        API_AUTH_EXCEPTIONS
      ),
  });

  const baseQueryWithRefreshToken: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    object,
    FetchBaseQueryMeta
  > = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const state = api.getState() as {
            token: ReturnType<typeof tokenSlices>;
          };
          const refreshResult = await baseQuery(
            {
              url: AUTH_API_URLS.refreshToken,
              method: "POST",
              body: {
                refreshToken: state.token.refreshToken,
              },
            },
            api,
            extraOptions
          );
          if (refreshResult.data) {
            const payload = refreshResult.data as ApiResponse<TokenProps>;
            api.dispatch({
              type: "token/setAccessToken",
              payload: payload.data.token,
            });
            api.dispatch({
              type: "token/setRefreshToken",
              payload: payload.data.refreshToken,
            });
            result = await baseQuery(args, api, extraOptions);
          } else {
            window.location.href = "/login";
          }
        } finally {
          release();
        }
      }
    }
    return result;
  };

  return retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const result = await baseQueryWithRefreshToken(args, api, extraOptions);

      if (result.meta?.request.method !== "GET" && result.error) {
        retry.fail(result.error);
      }

      return result;
    },
    {
      maxRetries: Number(process.env.REACT_APP_API_FETCH_MAX_RETRIES),
    }
  );
};

const prepareAuthHeaders = (
  headers: Headers,
  state: { token: ReturnType<typeof tokenSlices> },
  endpoint?: string,
  exceptions?: string[]
) => {
  if (exceptions && endpoint && exceptions.includes(endpoint)) {
    return headers;
  }

  const accessToken = state.token.accessToken;

  if (accessToken) {
    headers.set("x-auth-token", `${accessToken}`);
  }

  return headers;
};

export const getDataFromAccessToken = (
  accessToken: string
): { userId: string | null; workspaceId: string | null } => {
  const decodedToken = decodeJwt<JwtInfo>(accessToken);

  return {
    userId: decodedToken?.id ? decodedToken.id : null,
    workspaceId: decodedToken?.workspaceId ? decodedToken.workspaceId : null,
  };
};

export const clearAccessTokenFromStorage = () => {
  LocalStorageService.remove(AuthValueKey.ACCESS_TOKEN);
};

export const clearAuthStorage = () => {
  clearAccessTokenFromStorage();
  LocalStorageService.remove(AuthValueKey.REFRESH_TOKEN);
};
