import styled from "styled-components";

export const Form = styled("form")({
  display: "flex",
  flexDirection: "column",
  minWidth: "300px",
  gap: "1rem",
});

export const FormField = styled("div")({
  display: "flex",
  flexDirection: "column",
});
