import { Text } from "@fluentui/react-components";
import React from "react";
import { Container, Statuses } from "./ConnectionInfo.styles";
import IconTextStatus from "../IconTextStatus/IconTextStatus";
import { LiveRegular, LocationLiveRegular } from "@fluentui/react-icons";

const ConnectionInfo: React.FC = () => {
  return (
    <Container>
      <Text font='base' size={300} weight='medium'>
        Povezava
      </Text>
      <Statuses>
        <IconTextStatus
          Icon={LocationLiveRegular}
          text='Telemetrija'
          status='Ni povezano'
          color='danger'
        />
        <IconTextStatus
          Icon={LiveRegular}
          text='Prenos slike'
          status='Ne oddaja'
          color='informative'
        />
      </Statuses>
    </Container>
  );
};

export default ConnectionInfo;
