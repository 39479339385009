import React, { useMemo } from "react";
import { Container } from "./IconStatus.styles";
import {
  PlugConnectedCheckmarkRegular,
  PlugDisconnectedRegular,
} from "@fluentui/react-icons";
import { Caption1 } from "@fluentui/react-components";
import { IconStatusProps, IconStatusStatus } from "./IconStatus.interface";

const IconStatus: React.FC<IconStatusProps> = ({ status }) => {
  const isConnected = useMemo(
    () => status === IconStatusStatus.CONNECTED,
    [status]
  );
  return (
    <Container $isConnected={isConnected}>
      {isConnected ? (
        <PlugConnectedCheckmarkRegular fontSize='16' />
      ) : (
        <PlugDisconnectedRegular fontSize='16' />
      )}
      <Caption1>{isConnected ? "Povezano" : "Ni povezano"}</Caption1>
    </Container>
  );
};

export default IconStatus;
